@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@500&display=swap");

a,
button,
::placeholder,
h1,
h2,
h3,
h4,
h5,
h6,
p,
tr,
th,
td span,
button,
label,
input {
  font-family: "Poppins", sans-serif !important;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background: black;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

html::-webkit-scrollbar-thumb {
  background: rgb(200, 144, 144);
  border: 3px solid rgb(71, 71, 82);
}


.sliderimage{
    max-height: 600px;
    width: auto;
}
.sliderPrnt{
  display: flex !important;
  justify-content: center;
  align-items: center;
}



.news-feed {
  background-color: #333;
  color: white;
  padding: 10px;
  overflow: hidden;
}

.ticker-row {
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}

.ticker-content {
  display: inline-block;
  animation: ticker-animation 30s linear infinite;
}

.ticker-content p {
  display: inline;
  margin-right: 20px;
}

@keyframes ticker-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}




/* p {
  margin-bottom: 1rem !important;
} */
.bgimg:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background: url(/public/assets/bgimg.png) no-repeat center center;
  background-size: cover;
  mix-blend-mode: multiply;
  opacity: 0.2;
}

.bgimg:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 30vh;
  background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}

.bgimg {
  background: linear-gradient(62deg, #e52b09 30%, #ff9a24 72%);
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  height: 85vh;
  padding-bottom: 10vh;
  position: relative;
}

.secondimg:before {
  position: absolute;
  content: "";
  background: url(/public/assets/second.png) no-repeat center center;
  background-size: 100%;
  top: 24%;
  right: 18%;
  width: 336px;
  height: 210px;
  z-index: 4;
}

.bgparticle {
  text-align: center;
  background: url(/public/assets/conceptbg.png) no-repeat center center;
  background-size: 100%;
}

.thridimg {
  text-align: center;
  background: url(/public/assets/third.png) no-repeat center center;
  background-size: 150%;

  z-index: 3;
}

.bgarrow {
  display: flex;
  background: url(/public/assets/arrow.png) no-repeat top center;
  background-size: 22px;
  align-items: center;
}
.search-sec svg {
  color: #b6503c;
}

.ant-tabs-tab {
  background-image: url("./images/buttonbg.png") !important;
  background-repeat: no-repeat !important;
  border-radius: 10px !important;
  width: 200px !important;
  font-size: 1.125rem !important;
  /* line-height: 1.75rem !important; */
  background-size: cover !important;
  color: white !important;
  border: 0 !important;
  justify-content: center !important;
}

@media screen and (max-width: 768px) {
  .ant-tabs-tab {
    font-size: 0.875rem !important;
    width: 100px !important;
    padding: 10px 14px !important;
    border: none !important;
  }
}

.ant-tabs-top > .ant-tabs-nav::before {
  border: none !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: white !important;
}

.ant-tabs-tab-btn {
  font-family: "Poppins", sans-serif;
}

.bg {
  background: linear-gradient(214.02deg, #b6503c 6.04%, #57466b 92.95%);
}

.bg1 {
  background: linear-gradient(135deg, #347de6 0%, #ff00ee 100%);
}

.ant-modal {
  border: none !important;
}

.ant-space {
  justify-content: space-between;
  display: flex !important;
}

.anticon svg {
  color: grey;
}

.make-offer p {
  margin-bottom: 0 !important;
}

.ant-dropdown-menu {
  padding: 0 !important;
  box-shadow: none !important;
}

.activeButton {
  background-color: #ff00ee;
  color: blue;
}
.goog-te-gadget {
  font-size: 0px !important;
}
.select .option {
  background-color: #347de6 !important;
}
.goog-te-combo {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
  font-family: "Poppins", sans-serif !important;
  width: 180px !important;
}
.goog-logo-link {
  display: none !important;
}

.sidebar {
  height: 100vh;
}
.VIpgJd-ZVi9od-l4eHX-hSRGPd {
  display: none !important;
}

.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .menu {
    display: none;
  }
}
.logo {
  height: 32px;
  margin-left: 1rem;
}

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 20px !important;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    pointer-events: auto;
}
.ant-modal-footer {
    display:none;
}
.ant-picker-range{
	width: 100% !important;
}

/* .ant-menu-light {
  color: #fff;
  background: #001529 !important;
} */

/* Selects the second li element in a list */
.rup:nth-child(1) {
  background: #1B1464;
}
.rup:nth-child(2) {
  background:#F8931F;
}
.rup:nth-child(3) {
  background: #0071BD;
}
.rup:nth-child(4) {
  background: #00A99E;
}


:where(.css-dev-only-do-not-override-acm2ia).ant-layout.ant-layout-has-sider {
  flex-direction: row;
  height: 100%;
}
.login-wrap{
	width:100%;
	margin:auto;
	max-width:525px;
	min-height:670px;
	position:relative;

	box-shadow:0 12px 15px 0 rgba(0,0,0,.24),0 17px 50px 0 rgba(0,0,0,.19);
}
.login-html {
  width: 100%;
  height: 100%;
  position: absolute;
 padding:35px;
  /* background: rgb(0 21 41 / 83%); */
}
.login-html .sign-in-htm,
.login-html .sign-up-htm{
	top:0;
	left:0;
	right:0;
	bottom:0;
	position:absolute;
	transform:rotateY(180deg);
	backface-visibility:hidden;
	transition:all .4s linear;
}
.login-html .sign-in,
.login-html .sign-up,
.login-form .group .check{
	display:none;
}
.login-html .tab,
.login-form .group .label,
.login-form .group .button{
	text-transform:uppercase;
}
.login-html .tab {
    font-size: 22px;
    margin-right: 15px;
    padding-bottom: 5px;
    margin: 0 15px 10px 0;
   
    color: #57466b;
    display: inline-block;
    border-bottom: 2px solid transparent;
    font-weight: 600;
}
.login-html .sign-in:checked + .tab,
.login-html .sign-up:checked + .tab{
	color: #57466b;
	border-color:#B6503C;
}
.login-form{
	min-height:345px;
	position:relative;
	perspective:1000px;
	transform-style:preserve-3d;
}
.login-form .group{
	margin-bottom:15px;
}
.login-form .group .label,
.login-form .group .input,
.login-form .group .button{
	width:100%;

	display:block;
}
.login-form .group .input, .login-form .group .button {
    border: none;
    padding: 10px 20px;
    border-radius: 9px;
    /* background: rgb(0 0 0 / 98%); */
    border: 1px solid #ccc;
}
.login-form .group input[data-type="password"]{
	text-security:circle;
	-webkit-text-security:circle;
}
.login-form .group .label {
    color: #57466b;
    font-size: 16px;
    font-weight: 600;
}
.login-form .group .button{
	background:#1161ee;
}
.login-form .group label .icon{
	width:15px;
	height:15px;
	border-radius:2px;
	position:relative;
	display:inline-block;
	background:rgba(255,255,255,.1);
}
.login-form .group label .icon:before,
.login-form .group label .icon:after{
	content:'';
	width:10px;
	height:2px;
	background:#fff;
	position:absolute;
	transition:all .2s ease-in-out 0s;
}
.login-form .group label .icon:before{
	left:3px;
	width:5px;
	bottom:6px;
	transform:scale(0) rotate(0);
}
.login-form .group label .icon:after{
	top:6px;
	right:0;
	transform:scale(0) rotate(0);
}
.login-form .group .check:checked + label{
	color:#fff;
}
.login-form .group .check:checked + label .icon{
	background:#1161ee;
}
.login-form .group .check:checked + label .icon:before{
	transform:scale(1) rotate(45deg);
}
.login-form .group .check:checked + label .icon:after{
	transform:scale(1) rotate(-45deg);
}
.login-html .sign-in:checked + .tab + .sign-up + .tab + .login-form .sign-in-htm{
	transform:rotate(0);
}
.login-html .sign-up:checked + .tab + .login-form .sign-up-htm{
	transform:rotate(0);
}

.hr{
	height:2px;
	margin-top:20px;
	margin-bottom:10px;
	background:#57466b
}
.foot-lnk{
	text-align:center;
}
.ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #001529!important;
    transition: all 0.2s;
}
/* @media screen and (min-width:768px) {
.ant-menu-light {
    color: #000!important;
    background: #fff !important;
}
} */
.ant-menu {

    color: #fff;
   
    background: 0!important;
}
 @media screen and (max-width:768px) {
.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
  
    color: #000;
}}